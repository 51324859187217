<template>
  <div>
    <div class="dashboard__container pb-2 pt-2" v-if="eventInfo && eventInfo.id">
      <span class="flex align-start flex-wrap">
        <router-link :to="`/orders/${$route.params.id}/time/timesheets`">
          <button class="btn btn__small mr-3 mb-2" v-bind:class="{ 'btn__primary': isTheTimesheets, 'btn__dark btn__small': !isTheTimesheets }">Timesheets</button>
        </router-link>
        <router-link :to="`/orders/${$route.params.id}/time/checkin`" v-if="eventInfo && eventInfo.workerType && eventInfo.workerType == 'Contractors'">
          <button class="btn btn__small mr-3 mb-2" v-bind:class="{ 'btn__primary': isCheckin, 'btn__dark btn__small': !isCheckin }">Check-In</button>
        </router-link>
        <router-link :to="`/orders/${$route.params.id}/time/upload`">
          <button class="btn btn__small mr-3 mb-2" v-bind:class="{ 'btn__primary': isUpload, 'btn__dark btn__small': !isUpload }">Upload Timesheets</button>
        </router-link>

        

      </span>
    </div>
    <div class="dashboard__container pb-0 pt-0">
      <Loader v-if="!eventInfo || !eventInfo.id" />
      <!-- <router-view :key="$route.params.id" :eventInfo="eventInfo" :eventShifts="eventShifts" :eventShiftsDays="eventShiftsDays" :eventUsers="eventUsers" /> -->
      <router-view :key="$route.params.id" :eventInfo="eventInfo" />
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import router from '@/router'

export default {
  name: 'timeHome',
  computed: {
    // ...mapState(['currentUser', 'userProfile', 'eventInfo', 'eventShifts', 'eventShiftsDays', 'eventUsers']),
    ...mapState(['currentUser', 'userProfile', 'eventInfo']),
    isTheTimesheets() {
      return this.$route.name == 'timeTimesheets';
    },
    isCheckin() {
      return this.$route.name == 'timeCheckin';
    },
    isUpload() {
      return this.$route.name == 'uploadTimesheet';
    },
  },
  components: {
    Loader,
  },
  methods: {
    goBack() {
      router.go(-1)
    },
    
  },
}

</script>